<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Report Idoneità</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Data:
              </ion-label>
              <ion-datetime id="ricconsulente"  v-model="search.date"></ion-datetime>  
            </ion-col>
            <ion-col id="rcconsulente">
              <ion-label id="lconsulente">
                Risorsa:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.user"></ion-input>  
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-button class="btncerca" @click="getIdoneities()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>

        <ion-button id="export" @click="esportaExcel()" style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon>
        </ion-button>
         <dtable 
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="idoneityHeading"
            :data="idoneities"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getIdoneities"
          >
            <template v-slot:ask1="slotProps">
              <div  style="backgorund-color:#f57676">
              <span v-if="slotProps.row.ask1==1">Sì</span>
              </div> 
              <div style="backgorund-color:##a5f593">
              <span v-if="slotProps.row.ask1==2">No</span>
              </div>
            </template>
            <template v-slot:ask2="slotProps">
              <span v-if="slotProps.row.ask2==1" style="backgorund-color:#f57676">Sì</span>
              <span v-if="slotProps.row.ask2==2" style="backgorund-color:##a5f593">No</span>
            </template>
            <template v-slot:ask3="slotProps">
              <span v-if="slotProps.row.ask3==1" style="backgorund-color:#f57676">Sì</span>
              <span v-if="slotProps.row.ask3==2" style="backgorund-color:##a5f593">No</span>
            </template>
            <!--
            <template v-slot:ask4="slotProps">
              <span v-if="slotProps.row.ask4==1" style="backgorund-color:#f57676">Sì</span>
              <span v-if="slotProps.row.ask4==2" style="backgorund-color:##a5f593">No</span>
            </template>
            <template v-slot:ask5="slotProps">
              <span v-if="slotProps.row.ask5==1" style="backgorund-color:##a5f593">Sì</span>
              <span v-if="slotProps.row.ask5==2" style="backgorund-color:#f57676">No</span>
            </template>
            -->
          </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButtons,
    IonButton,
    IonCol,   
    IonContent,
    IonDatetime,
    IonGrid,   
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel, 
    IonMenuButton, 
    IonPage,
    IonRow,
    IonTitle, 
    IonToolbar,
    } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline} from 'ionicons/icons';


  export default {
    name: 'Folder',
    components: { 
      IonButtons,
      IonButton,
      IonCol,   
      IonContent,
      IonDatetime,
      IonGrid,   
      IonHeader,
      IonIcon,
      IonInput,
      IonLabel, 
      IonMenuButton, 
      IonPage,
      IonRow,
      IonTitle, 
      IonToolbar,
      Dtable
    },
  data: function(){
    return{
      idoneities:[],
      idoneityHeading: [
        {
          text: 'Data',
          code: 'date'
        },
        {
          text: 'Risorsa',
          code: 'user'
        },
        {
          text: 'Febbre',
          code: 'ask1'
        },
        {
          text: 'Sintomi influenzali',
          code: 'ask2'
        },
        {
          text: 'Contatti con positivi',
          code: 'ask3'
        },
        /*
        {
          text: 'Mezzi pubblici',
          code: 'ask4'
        },
        {
          text: 'DPI',
          code: 'ask5'
        },
        */
      ],
      table: {
        currentPage: 1,
        total: '',
        perPage: 0,
        orderBy: 'date',
        order: 'desc',
      },
      user:{
        id:0,
        name:'',
        role:0,
      },
      downloadOutline:downloadOutline,
      search:{
        user:'',
        date:'',
      }
    }
  },
  methods:{
    getIdoneities(obj = false){
      if(obj) {
        this.table.currentPage = obj.page
        this.table.orderBy = obj.orderBy
        this.table.order = obj.order
      }
      this.axios.post(this.apiUrl + 'get-idoneities', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search}, this.headers)
      .then(async (response) => {
        this.idoneities=response.data.idoneities.data
        this.table.total = response.data.idoneities.total
        this.table.perPage = response.data.idoneities.per_page
      })
    },
    getUserInfo(){
      this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
      .then(async (response) => {
        this.user=response.data.user;
      })
    },
    esportaExcel(){
      window.open('http://backend.hbj-group.com/download-excel-idoneities?&user='+this.search.user+'&date='+this.search.date +'&token='+this.$store.state.apiToken,'_blank')
    },
    svuotaFiltro(){
      this.search.date='';
      this.search.user='';

      this.getIdoneities();
    }
  },
  ionViewWillEnter: function(){
    this.getUserInfo();
    this.getIdoneities();  
  }
}
</script>

<style scoped>
  #container{
    width:90%;
    margin:5%;
  }
    #ricdatainizio,#ricdatafine,#ricconsulente,#ricconsulente,#rictipologia,#ricorainizio,#ricorafine,#ricstatus{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lstatus,#lconsulente,#ltipologia,#ldatainizio,#ldatafine,#lorainizio,#lorafine{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
   .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
</style>